export const globalConst = {
  // 전파세기
  rssiWarningdBm: -100,
  rssiMinimum: -101,
  rssiMaximum: -140,
  //기본 이미지 크기
  defaultImageWidth: 320,
  defaultImageHeight: 144,
  //이미지 크기 제한
  limitImageWidth: 600,
  limitImageHeight: 300,
  //
}

export const globalText = {
  installInside: '내부설치',
  installOutside: '외부설치',
}

/**
 * 수용가 번호에 해당 지자체에서 관리하는 번호 처럼 "-" 추가
 *
 * @param officeId : 해당 수용가 번호의 officeId
 * @param number : 해당 수용가 번호
 */
export const globalFormatWithHyphens = (officeId: any, number: any) => {
  const numLength: {[key: string]: number[]} = {
    '7300': [12],
    B300: [13],
    '4001': [14],
    '4002': [14],
    '8400': [13, 14], // 13자리와 14자리를 허용
    A800: [15],
  }

  const formatPatterns: {[key: string]: RegExp} = {
    '7300': /(\d{3})(\d{3})(\d{4})(\d{2})/,
    B300: /(\d{2})(\d{2})(\d{3})(\d{6})/,
    '4001': /(\d{1})(\d{2})(\d{3})(\d{3})(\d{5})/,
    '4002': /(\d{1})(\d{2})(\d{3})(\d{3})(\d{5})/,
    '8400': /(\d{2})(\d{3})(\d{4})(\d{4})/,
    A800: /(\d{3})(\d{3})(\d{6})(\d{3})/,
  }

  const formatReplacedPatterns: {[key: string]: string} = {
    '7300': '$1-$2-$3-$4',
    B300: '$1-$2-$3-$4',
    '4001': '$1-$2-$3-$4-$5',
    '4002': '$1-$2-$3-$4-$5',
    '8400': '$1-$2-$3-$4',
    A800: '$1-$2-$3-$4',
  }

  let numToString = number.toString()

  const requiredLengths = numLength[officeId]
  const pattern = formatPatterns[officeId]
  const replacedPattern = formatReplacedPatterns[officeId]

  if (!requiredLengths || !requiredLengths.includes(numToString.length) || !pattern) {
    return numToString
  }

  if (officeId === '8400' && numToString.length === 13) {
    return numToString.replace(pattern, replacedPattern)
  }

  if (officeId === '8400' && numToString.length === 14) {
    const formatted = numToString.slice(0, 13).replace(pattern, replacedPattern)
    const lastDigit = numToString.slice(13)
    return `${formatted}-${lastDigit}`
  }

  return numToString.replace(pattern, replacedPattern)
}

export const HyphensOfficeHandler = (id: any) => {
  const allowed = ['00', '7300', 'B300', '4001', '4002', '8400', 'A800']
  if (allowed.includes(id)) {
    return true
  }
  const idPrefix = id.slice(0, 2)
  const allowedPrefixes = allowed.map((item) => item.slice(0, 2))
  if (allowedPrefixes.includes(idPrefix)) {
    return true
  }

  return false
}

/**
 * 누수의심 계산 model 기존 버전 "1", 개선 버전 "2"
 */
export const leakModelController = {
  model: '2',
}

export const leakModelHandler = (serverName: any, officeID: any) => {
  switch (serverName) {
    case 'prod':
    case 'dev':
      switch (officeID) {
        case 'B600':
        case 'B6':
          return '2'
        default:
          return '1'
      }

    default:
      return '2'
  }
}

/**
 * 엔티모어 장비 임시 방편  디폴트"1", 엔티모어 "2"
 */
export const entimoreController = {
  model: '1',
}
