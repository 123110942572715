import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {useAuth} from '../../_component/modules/auth'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import CustomerActivity from './component/CustomerActivity'
import CustomerAllocation from './component/CustomerAllocation'
import CustomerChecklist from './component/CustomerChecklist'
import CustomerCheckReport from './component/CustomerCheckReport'
import CustomerClient from './component/CustomerClient'
import CustomerFreezeWarning from './component/CustomerFreezeWarning'
import CustomerInfo from './component/CustomerInfo'
import CustomerInspection from './component/CustomerInspection'
import CustomerLeakage from './component/CustomerLeakage'
import CustomerMinimumFlow from './component/CustomerMinimumFlow'
import CustomerRegister from './component/CustomerRegister'
import CustomerStatus from './component/CustomerStatus'
import CustomerUnused from './component/CustomerUnused'
import CustomerHeader from './layout/CustomerHeader'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '스마트 미터링 시스템',
    path: '/pages/customer',
    isSeparator: false,
    isActive: false,
  },
  // {
  //   title: '사회복지 서비스',
  //   path: '/pages/customer/activity',
  //   isSeparator: false,
  //   isActive: false,
  // },
]

const CustomerPage = (props: any) => {
  const {toast} = props
  const {logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' &&
        userInfo.accountType !== 'sys_user' &&
        userInfo.accountType !== 'gov_admin' &&
        userInfo.accountType !== 'gov_meterman' &&
        userInfo.accountType !== 'customer_client')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])
  return (
    <Routes>
      <Route
        element={
          <>
            <CustomerHeader toast={toast} />
            <Outlet />
          </>
        }
      >
        <Route
          path={'register'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>수용가 등록</PageTitle>
              <CustomerRegister toast={toast} />
            </>
          }
        />
        <Route
          path={'allocation'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>수용가 할당</PageTitle>
              <CustomerAllocation toast={toast} />
            </>
          }
        />
        <Route
          path={'info'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>수용가 정보조회</PageTitle>
              <CustomerInfo toast={toast} />
            </>
          }
        />
        <Route
          path={'activity'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>건강 모니터링</PageTitle>
              <CustomerActivity toast={toast} />
            </>
          }
        />
        <Route
          path={'checklist'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>검침내역</PageTitle>
              <CustomerChecklist />
            </>
          }
        />
        <Route
          path={'check-report'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>검침 리포트</PageTitle>
              <CustomerCheckReport toast={toast} />
            </>
          }
        />
        <Route
          path={'minimum-flow'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>최소유량 조회</PageTitle>
              <CustomerMinimumFlow toast={toast} />
            </>
          }
        />
        <Route
          path={'customer-status'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>수용가 현황</PageTitle>
              <CustomerStatus />
            </>
          }
        />

        <Route
          path={'customer-leakage'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>누수의심 조회</PageTitle>
              <CustomerLeakage />
            </>
          }
        />

        <Route
          path={'unused'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>장기미사용 조회</PageTitle>
              <CustomerUnused />
            </>
          }
        />

        <Route
          path={'freeze-warning'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>동파위험 조회</PageTitle>
              <CustomerFreezeWarning />
            </>
          }
        />

        <Route
          path={'inspection'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>점검</PageTitle>
              <CustomerInspection />
            </>
          }
        />

        <Route
          path={'client'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>수용가</PageTitle>
              <CustomerClient />
            </>
          }
        />
      </Route>
      {/*</Route>*/}
    </Routes>
  )
}
export default CustomerPage
