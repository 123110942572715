/* eslint-disable jsx-a11y/anchor-is-valid */
import {Tooltip} from '@mui/material'
import {FC} from 'react'
import {isMobileDevice} from '../../assets/ts/_utils'
import {toAbsoluteUrl} from '../../helpers'
import {useLayout} from '../core'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1 my-3'>
          <span className='text-muted fw-bold me-2'>{2023} &copy;</span>
          <a
            href='https://www.young-it.co.kr'
            target={'_blank'}
            className='text-gray-500 text-hover-primary fw-bold'
            rel='noreferrer'
          >
            YOUNG-IT | Meterarium
          </a>
          <span className={'fw-bold ms-4 text-white'} hidden={isMobileDevice()}>
            Developed By :윤경윤 , 안현진
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1 mb-3 gap-6 align-items-center'>
          <li className='menu-item '>
            <a
              href='https://young-it.co.kr'
              target={'_blank'}
              className='menu-link pe-0 fs-5'
              rel='noreferrer'
            >
              <Tooltip
                title={<span className={'fs-6'}>영아이티 홈페이지</span>}
                className={''}
                arrow={true}
              >
                <img
                  src={toAbsoluteUrl('/media/logos/youngit_logo_mini.png')}
                  alt={'youngit'}
                  className={!isMobileDevice() ? 'h-30px' : 'h-25px  '}
                />
              </Tooltip>
            </a>
          </li>

          <li className='menu-item align-self-center '>
            <a
              href='http://pf.kakao.com/_rxbZaxj'
              className='menu-link pe-12 fs-5'
              target={'_blank'}
              rel='noreferrer'
            >
              <Tooltip
                title={<span className={'fs-6'}>영아이티 카카오채널 연결</span>}
                className={''}
                arrow={true}
              >
                <img
                  src={toAbsoluteUrl('/media/logos/youngit-kakao.png')}
                  alt={'kakaoch'}
                  className={window.innerWidth > 1300 ? 'h-35px' : 'h-25px'}
                />
              </Tooltip>
            </a>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
