import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Popper,
  Select,
  TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import {envBackHost} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import _ from 'lodash'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {confirmAlert, customConfirm, normalAlert, resultAlert} from 'utils/CustomAlert/Alert'
import {isEmail, isPhone} from 'utils/validation/validation'

const UpdateUserModal = (props: any) => {
  const {open, close, reset, data, getAccountList, accountTypeList, officeList, cooperList} = props
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const [updateData, setUpdateData] = useState<any>({
    name: data?.name,
    phoneNumber: data?.phoneNumber,
    email: data?.email,
    accountType: data?.accountType,
    idActiveStatus: data?.idActiveStatus,
    team: '1',
    memo: data?.memo,
    officeId: data?.officeId,
    cooperIdx: data?.cooperIdx,
    imageUrl: data?.imageUrl,
    id: data?.id,
    updateId: userInfo?.id,
  })
  const [emailForm, setEmailForm] = useState<any>(false)
  const [phoneNumberForm, setPhoneNumberForm] = useState<any>(false)
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }
  const emailValidation = (e: any) => {
    const {value} = e.target
    if (isEmail(value)) {
      setEmailForm(true)
    } else {
      setEmailForm(false)
    }
  }
  const phonenValidation = (e: any) => {
    const {value} = e.target

    if (isPhone(value)) {
      setPhoneNumberForm(true)
    } else {
      setPhoneNumberForm(false)
    }
  }
  const buttonHandler = async () => {
    if (
      updateData?.name === data.name &&
      updateData?.phoneNumber === data.phoneNumber &&
      updateData?.email === data.email &&
      updateData?.accountType === data.accountType &&
      updateData?.idActiveStatus === data.idActiveStatus &&
      updateData?.memo === data.memo &&
      updateData?.officeId === data.officeId &&
      updateData?.cooperIdx === data.cooperIdx &&
      updateData?.imageUrl === data.imageUrl
    ) {
      await normalAlert('변경 사항이 없습니다..')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/account`, updateData)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            close()
            getAccountList()
            return
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }
  const deleteAccount = async () => {
    if (await confirmAlert('삭제', 'info')) {
      if (await customConfirm('삭제시 복구는 불가합니다.', 'warning', '동의 후 삭제')) {
        axiosWithAuth()
          .delete(`${envBackHost}/account/${data?.id}`)
          .then(async (res) => {
            await resultAlert(res.data)
            if (res.data.resultCode === 0) {
              close()
              getAccountList()
              return
            }
          })
          .catch(async (e) => {
            await resultAlert({resultCode: e.response.status})
          })
      } else {
        return
      }
    }
  }
  const CustomPopper = (props: any) => {
    return <Popper {...props} placement='top' />
  }

  useEffect(() => {
    setUpdateData({
      phoneNumber: data?.phoneNumber,
      accountType: data?.accountType,
      idActiveStatus: data?.idActiveStatus,
      name: data?.name,
      officeId: data?.officeId,
      email: data?.email,
      cooperIdx: data?.cooperIdx,
      imageUrl: data?.imageUrl,
      team: '1',
      memo: data?.memo ? data?.memo : '',
      id: data?.id,
      updateId: userInfo?.id,
    })
    setPhoneNumberForm(true)
    setEmailForm(true)
  }, [data])

  console.log('url', data)
  return (
    <Modal
      open={open}
      onClose={() => {
        close()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style} id={'create-user-modal-box'}>
        <div
          className={'w-100 d-flex justify-content-between mb-5'}
          id={'create-user-modal-header'}
        >
          <Chip
            label={<span>미터리움 계정수정</span>}
            variant={'outlined'}
            sx={{fontSize: '20px', fontWeight: 'bold'}}
          />
          <CancelPresentationIcon
            sx={{fontSize: '40px', cursor: 'pointer'}}
            color={'error'}
            onClick={() => {
              close()
              reset()
            }}
          />
        </div>
        <div
          className={'container-fluid  align-items-center '}
          style={{height: '90%', display: 'grid'}}
        >
          <div className={'d-flex p-4'} style={{gap: 20}}>
            <div
              className={'px-1'}
              style={{
                width: '100%',
                height: '100%',
                overflowY: 'scroll',
                overflowX: 'hidden',
                margin: '0 0 20px 20px',
              }}
              id={'create-user-textField'}
            >
              <div className={'row gap-4 py-4 '}>
                <div className={'row gap-1'} style={{paddingRight: 0}}>
                  <div className={'d-flex gap-3'} style={{paddingRight: 0}}>
                    <TextField
                      className={'w-100'}
                      id='id-textField'
                      label='사용자 ID'
                      variant='outlined'
                      disabled
                      value={data?.id}
                      autoComplete={'off'}
                      name={'id-check-field'}
                    />
                    <TextField
                      className={'w-100'}
                      id='id-textField'
                      label='이름'
                      variant='outlined'
                      defaultValue={data?.name}
                      autoComplete={'off'}
                      name={'name-field'}
                      onChange={(e) => {
                        setUpdateData({...updateData, name: e.target.value})
                      }}
                    />
                  </div>
                </div>
                <div className={'d-flex gap-3'}>
                  <TextField
                    required
                    id='client-realName'
                    label='연락처'
                    error={!phoneNumberForm}
                    variant='outlined'
                    autoComplete={'off'}
                    fullWidth
                    defaultValue={data?.phoneNumber}
                    onChange={(e) => {
                      phonenValidation(e)
                      setUpdateData({
                        ...updateData,
                        name: e.target.value,
                      })
                    }}
                    helperText={
                      updateData?.phoneNumber && !phoneNumberForm ? (
                        <span className={'text-danger fs-8'}>
                          전화번호 형식이 올바르지않습니다.
                        </span>
                      ) : (
                        ''
                      )
                    }
                  />
                  <TextField
                    required
                    id='client-realName'
                    label='이메일'
                    variant='outlined'
                    autoComplete={'off'}
                    fullWidth
                    defaultValue={data?.email}
                    onChange={(e) => {
                      emailValidation(e)
                      setUpdateData({
                        ...updateData,
                        email: e.target.value,
                      })
                    }}
                    helperText={
                      !emailForm ? (
                        <span className={'text-danger fs-8'}>이메일 형식이 올바르지않습니다.</span>
                      ) : (
                        ''
                      )
                    }
                  />
                </div>
                <div className={'d-flex gap-3'}>
                  <FormControl fullWidth className={'px-0'}>
                    <InputLabel id='demo-simple-select-label'>권한</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      className={'px-0'}
                      id='metervision-authority'
                      defaultValue={data?.accountType}
                      label='권한'
                      onChange={(e) => setUpdateData({...updateData, accountType: e.target.value})}
                    >
                      {userInfo?.accountType !== 'sys_admin'
                        ? accountTypeList
                            ?.filter((el: any) => el?.accountType !== 'sys_admin')
                            ?.map((el: any, i: any) => (
                              <MenuItem key={i} value={el?.accountType}>
                                {el?.accountTypeName}
                              </MenuItem>
                            ))
                        : accountTypeList?.map((el: any, i: any) => (
                            <MenuItem key={i} value={el?.accountType}>
                              {el?.accountTypeName}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>계정상태</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='customer-target'
                      label='계정상태'
                      defaultValue={data?.idActiveStatus}
                      onChange={(e) =>
                        setUpdateData({
                          ...updateData,
                          idActiveStatus: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={'true'}>활성화</MenuItem>
                      <MenuItem value={'false'}>비활성화</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={'d-flex gap-3'}>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id='choose-office'
                    disabled={userInfo?.accountType !== 'sys_admin'}
                    options={officeList}
                    defaultValue={_.find(officeList, ['officeName', data?.officeName])}
                    className={'px-0'}
                    disableClearable={true}
                    clearText={'초기화'}
                    noOptionsText={'검색 결과가 없습니다.'}
                    PopperComponent={CustomPopper}
                    getOptionLabel={(option: any) => option.officeName}
                    // value={selectedOffice}
                    onChange={(e, rowValue: any) => {
                      setUpdateData({
                        ...updateData,
                        officeId: rowValue?.officeId,
                      })
                    }}
                    sx={{fontSize: '1.5rem', color: '#fff'}}
                    renderInput={(params) => (
                      <div className={'d-flex gap-3'}>
                        <TextField
                          {...params}
                          fullWidth
                          label='사업소 선택'
                          focused
                          placeholder={'검색하거나 선택할 수 있습니다.'}
                        />
                      </div>
                    )}
                  />
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>단말기 제조사</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='customer-target'
                      label='단말기 제조사'
                      fullWidth
                      defaultValue={data?.cooperIdx}
                      onChange={(e) => {
                        setUpdateData({...updateData, cooperIdx: e.target.value})
                      }}
                    >
                      {_.map(cooperList, (el, i) => {
                        return (
                          <MenuItem key={i} value={el?.idx}>
                            {el?.cooperName}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <TextField label='팀' disabled focused value={data?.cooperName} fullWidth />
                </div>
                <div className={'d-flex gap-3 align-items-center'}>
                  {data?.imageUrl !== '' && (
                    <div style={{width: 180}} className={'border border-gray-600 rounded-2 p-2'}>
                      <img
                        src={data?.imageUrl}
                        alt='profile'
                        className={'rounded-3'}
                        width={'100%'}
                      />
                    </div>
                  )}
                  <TextField
                    fullWidth
                    label='이미지 URL'
                    focused
                    placeholder={'Ex) https://young-it.co.kr/test.png'}
                    onChange={(e) => {
                      setUpdateData({...updateData, imageUrl: e.target.value})
                    }}
                    defaultValue={data?.imageUrl}
                  />
                </div>
                <div className={'d-flex gap-3'}>
                  <TextField
                    id='outlined-basic'
                    label='메모'
                    variant='outlined'
                    multiline
                    rows={2}
                    fullWidth
                    defaultValue={data?.memo}
                    onChange={(e) => {
                      setUpdateData({...updateData, memo: e.target.value})
                    }}
                  />
                </div>
                <div
                  className={'d-flex gap-3 px-0'}
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    backgroundColor: 'white',
                  }}
                >
                  <Button
                    variant={'contained'}
                    color={'error'}
                    fullWidth
                    onClick={() => deleteAccount()}
                    style={{height: 50, fontSize: 20}}
                  >
                    삭제
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={() => buttonHandler()}
                    style={{height: 50, fontSize: 20}}
                  >
                    수정
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
export default UpdateUserModal
