import {DataGridPro} from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import {customerFreezeWarningColumns} from 'pages/customer/dataConfig/declearColumns'
import {customerFreezeWarningFilter} from 'pages/customer/dataConfig/declearFilters'
import {customerFreezeWarningRows} from 'pages/customer/dataConfig/declearRows'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {getFreezeWarningList} from 'utils/apiCalling/api'
import {entimoreController} from 'utils/const/globalConst'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {scrollTop} from 'utils/func/justUtils'
import {reduxType} from 'utils/interface/reduxType'
import {toolTipText} from 'utils/toolTips/toolTipText'
import {customerInfoGroup} from '../dataConfig/declearGroupingModel'

const CustomerFreezeWarning = (props: any) => {
  const unusedOptionsDataInit: any = {
    officeName: '',
    longtermStandard: '',
  }

  const [pageSize, setPageSize] = useState<number>(10)
  const [freezeWarningList, setFreezeWarningList] = useState<any>([])
  const whatInfo = useSelector((state: any) => state)
  const officeInfo = useSelector((state: reduxType) => state.officeInfo)
  const cooperInfo = useSelector((state: reduxType) => state.cooperInfo)
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [filter, setFilter] = useState<any>(customerFreezeWarningFilter)
  const [unusedOptionsData, setunusedOptionData] = useState<any>(unusedOptionsDataInit)

  const getUnusedCustomerList = () => {
    entimoreController.model !== '2' &&
      getFreezeWarningList(officeInfo, cooperInfo, filter, {
        success: (res: any) => {
          console.log(res)
          setFreezeWarningList(res)
        },
        fail: (err: any) => {
          console.log(err)
        },
        loading: (loading: boolean) => {
          setLoading(loading)
        },
        count: (count: number) => {
          setTotalCount(count)
        },
      })
  }

  const columns: any = customerFreezeWarningColumns(filter)
  const columnGroupingModel = customerInfoGroup
  const rows = customerFreezeWarningRows(freezeWarningList)

  useEffect(() => {
    scrollTop()
    getUnusedCustomerList()
  }, [officeInfo, cooperInfo, filter.freezeWarningType, filter.rowPerPage, filter.page])

  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerFreezeWarning.description}
        tooltipText={toolTipText.customerFreezeWarning.text}
        filter={filter}
        id={'customer-freeze-warning'}
        setFilter={setFilter}
        searchCallback={getUnusedCustomerList}
        total={totalCount}
        hideSearchOptions={true}
        control={['freezeWarning']}
        actions={['freezeWarning']}
        date={[{date: dayjs().format('YYYYMMDD')}]}
        asValue={''}
        searchOptions={[]}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        <DataGridPro
          columnGroupingModel={columnGroupingModel}
          experimentalFeatures={{columnGrouping: true}}
          rows={rows}
          rowCount={rows?.length}
          columns={columns}
          rowHeight={180}
          disableColumnMenu
          paginationMode={'server'}
          loading={loading}
          pagination={true}
          hideFooter={true}
          autoHeight={true}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
    </>
  )
}

export default CustomerFreezeWarning
