import {DataGridPro} from '@mui/x-data-grid-pro'
import {serverLocalName} from 'GLOBAL/envVariables'
import CustomerDetailInfoMdal from 'pages/common/CustomerDetailInfoMdal'
import CustomerTargetImageListModal from 'pages/common/CustomerTargetImageListModal'
import ExcelDownLoadModal from 'pages/common/ExcelDownloadModal'
import {
  customerStatusBoseongColumns,
  customerStatusColumns,
} from 'pages/customer/dataConfig/declearColumns'
import {customerStatusFilter} from 'pages/customer/dataConfig/declearFilters'
import {CustomerStatusGroup} from 'pages/customer/dataConfig/declearGroupingModel'
import {customerInfoRows} from 'pages/customer/dataConfig/declearRows'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {getCustomerList} from 'utils/apiCalling/api'
import {entimoreController} from 'utils/const/globalConst'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {
  customerDetailModalClose,
  customerDetailModalOpen,
  imageDetailModalClose,
  imageDetailModalOpen,
  scrollTop,
} from 'utils/func/justUtils'
import {toolTipText} from 'utils/toolTips/toolTipText'

const CustomerStatus = () => {
  const location = useLocation()
  const pageState = location?.state
  const serverName = serverLocalName
  const [deviceDetailData, setDeviceDetailData] = useState<any>()
  const [customerDetailData, setCustomerDetailData] = useState<any>(null)
  const [customerInfoList, setCustomerInfoList] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const cooperInfo = useSelector((state: any) => state?.cooperInfo)
  const [totalCount, setTotalCount] = useState<any>(null)
  const [filter, setFilter] = useState<any>(customerStatusFilter(pageState))
  const [imageDetailOpen, setImageDetailOpen] = useState<boolean>(false)
  const [customerDetailOpen, setCustomerDetailOpen] = useState<boolean>(false)
  const openImageDetailModal = () => imageDetailModalOpen(setImageDetailOpen)
  const openCustomerDetailModal = () => customerDetailModalOpen(setCustomerDetailOpen)
  const closeImageDetailModal = () => imageDetailModalClose(setImageDetailOpen)
  const closeCustomerDetailModal = () => customerDetailModalClose(setCustomerDetailOpen)

  const getCustomerInfo = async () => {
    await getCustomerList(
      officeInfo,
      cooperInfo,
      filter,
      (res: any, count: any) => {
        setCustomerInfoList(res)
        setTotalCount(count)
      },
      (error: any) => {},
      () => {
        setLoading(loading)
      }
    )
  }

  const columnHandler = (serverName: string): any => {
    switch (serverName) {
      case 'boseong':
      case 'dev':
        return customerStatusBoseongColumns(
          openImageDetailModal,
          openCustomerDetailModal,
          setCustomerDetailData,
          setDeviceDetailData
        )
      default:
        return customerStatusColumns(
          openImageDetailModal,
          openCustomerDetailModal,
          setCustomerDetailData,
          setDeviceDetailData
        )
    }
  }

  const rows = customerInfoRows(customerInfoList)

  useEffect(() => {
    scrollTop()
    getCustomerInfo()
  }, [
    filter?.efosStatus,
    filter?.deviceStatus,
    filter?.deviceType,
    filter?.batteryStatus,
    filter.rowPerPage,
    filter.page,
    officeInfo,
    cooperInfo,
  ])

  return (
    <>
      <MeterariumPageLayout
        headerDescription={toolTipText.customerStatus.description}
        tooltipText={toolTipText.customerStatus.text}
        filter={filter}
        id={'customer-status'}
        setFilter={setFilter}
        searchCallback={getCustomerInfo}
        total={totalCount}
        control={
          entimoreController.model === '2'
            ? ['battery', 'deviceType']
            : ['device', 'battery', 'deviceType']
        }
        searchOptions={['customerNumber', 'customerName', 'address', 'managerName']}
        actions={['download']}
        checkData={null}
        onDelete={null}
        onCancel={null}
        setTypeName={null}
      >
        <DataGridPro
          headerHeight={40}
          rowHeight={150}
          autoHeight={true}
          columnGroupingModel={CustomerStatusGroup}
          experimentalFeatures={{columnGrouping: true}}
          rows={rows}
          rowCount={rows?.length}
          columns={columnHandler(serverName)}
          disableColumnMenu
          loading={loading}
          paginationMode={'server'}
          hideFooterPagination={true}
          pagination={true}
          hideFooter={true}
        />
        <CustomPagination filter={filter} setFilter={setFilter} totalCount={totalCount} />
      </MeterariumPageLayout>
      <CustomerTargetImageListModal
        data={deviceDetailData}
        open={imageDetailOpen}
        onClose={closeImageDetailModal}
      />
      <CustomerDetailInfoMdal
        data={customerDetailData}
        open={customerDetailOpen}
        onClose={closeCustomerDetailModal}
        getList={getCustomerInfo}
      />
      <ExcelDownLoadModal type={'수용가 현황'} search={filter} />
    </>
  )
}
export default CustomerStatus
