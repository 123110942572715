import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import ListAltIcon from '@mui/icons-material/ListAlt'
import RotateLeftTwoToneIcon from '@mui/icons-material/RotateLeftTwoTone'
import SearchIcon from '@mui/icons-material/Search'
import {Backdrop, Button, Chip, Modal, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import {DateRangePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {DateRange} from '@mui/x-date-pickers-pro/DateRangePicker'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {yMarker} from 'assets/imageDeclear'
import dayjs, {Dayjs} from 'dayjs'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import fileDownload from 'js-file-download'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {confirmAlert, resultAlert, validationAlert} from '../../utils/CustomAlert/Alert'
import {CustomerDetailChart} from './customerDetailChart'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: !isMobileDevice() ? '90%' : '90%',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  // border: '2px solid #000',
  borderRadius: '12px',
  boxShadow: 24,
  p: !isMobileDevice() ? 4 : 2,
}
const DeviceTargetImageListModal = (props: any) => {
  const {data, open, onClose} = props
  const [date, setDate] = React.useState<DateRange<Dayjs>>([null, null])
  const [pageSize, setPageSize] = useState<number>(60)
  const [imageList, setImageList] = useState<any>(null)
  const [mapLoading, setMapLoading] = useState<boolean>(false)
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [filter, setFilter] = useState<any>({
    startDate: !undefined
      ? dayjs().subtract(7, 'day').format('YYYYMMDD')
      : date[0]?.format('YYYYMMDD'),
    endDate: !undefined ? dayjs().format('YYYYMMDD') : date[1]?.format('YYYYMMDD'),
  })

  const [updateCheckValue, setUpdateCheckValue] = useState<any>('')
  const getImageList = () => {
    // console.log('이미지테스트')
    // console.log(data)
    axiosWithAuth()
      .get(`${envBackHost}/device/${data?.imei}/checkmeter`, {
        params: filter,
      })
      .then((res) => {
        setImageList(res.data.res)
      })
  }
  const handleCellClick = (event: any) => {
    event.stopPropagation()
  }
  const getDownloadData = async () => {
    axiosWithAuth()
      .get(
        `${envBackHost}/device/${data?.imei}/checkmeter/excel?startDate=${filter.startDate}&endDate=${filter?.endDate}`,
        {
          responseType: 'blob',
        }
      )
      .then(async (res) => {
        await resultAlert({resultCode: 0})
        fileDownload(
          res.data,
          `${data?.serialNumber}단말기_${filter.startDate}~${filter.endDate}_검침기록.xlsx`
        )
        await document.getElementById('downloadModalClose')?.click()
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }
  const updateCheckValuePost = async (idxData: any, imei: any, updateId: any, checkValue: any) => {
    console.log(idxData, imei, updateId, updateCheckValue)
    if (updateCheckValue === '' || updateCheckValue === checkValue) {
      await validationAlert('수정값을 입력해주세요.', 'warning')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      await axiosWithAuth()
        .post(`${envBackHost}/checkmeter/update`, {
          idxDate: idxData,
          value: updateCheckValue,
          imei: imei,
          updateId: updateId,
        })
        .then(async (res) => {
          console.log(res.data)
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getImageList()
            setUpdateCheckValue('')
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }
  const columns: GridColDef[] = [
    {
      field: 'createAt',
      headerName: '검침일시',
      width: 260,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return <div>{dayjs(params?.row?.createAt).format('YYYY-MM-DD HH시 mm분 ss초')}</div>
      },
    },
    {
      field: 'filePath',
      headerName: '검침 이미지',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      hide: data?.deviceType === 'digital',
      renderCell: (params: any) => {
        return (
          <div>
            <img
              src={envImagePath + params?.row?.filePath}
              alt=''
              width={220}
              style={{transform: `rotate(${params?.row?.rotate}deg)`}}
            />
          </div>
        )
      },
    },

    {
      field: 'processStatus',
      headerName: '이미지 처리상태',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <span>
            {params.row.processStatus === 'digital' ? '디지털 계량기' : params.row.processStatus}
          </span>
        )
      },
    },
    {
      field: 'checkValue',
      headerName: '검침 값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.checkValue === '' || params.row.checkValue === null ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.checkValue
                )}
              </span>
              <span>
                {params.row.checkDecimalValue === '' || params.row.checkDecimalValue === null
                  ? ''
                  : '.' + params.row.checkDecimalValue?.split('.')[1]}
              </span>
            </div>
          </>
        )
      },
    },
    {
      field: 'valueUpdate',
      headerName: '검침값 수정',
      width: 280,
      align: 'center',
      headerAlign: 'center',
      hide: data?.deviceType === 'digital' || window.location.pathname?.includes('allocation'),
      renderCell: (params: any) => {
        return (
          <>
            <div
              className={'d-flex justify-content-center gap-3'}
              onClick={(e) => handleCellClick(e)}
            >
              <TextField
                sx={{textAlignLast: 'center'}}
                variant={'outlined'}
                size={'small'}
                defaultValue={params.row.checkValue}
                onChange={(e) => {
                  setUpdateCheckValue(e.target.value)
                }}
                disabled={params.row.processStatus === 'IMGERR'}
              />
              <Button
                variant={'contained'}
                color={buttonColor(params.row.processStatus)}
                size={'small'}
                onClick={() => {
                  updateCheckValuePost(
                    params.row.idxDate,
                    params.row.imei,
                    userInfo.userId,
                    params.row.checkValue
                  )
                }}
                disabled={params.row.processStatus === 'IMGERR'}
              >
                수정
              </Button>
            </div>
          </>
        )
      },
    },
  ]
  const buttonColor = (data: string) => {
    switch (data) {
      case 'COMPLETE':
        return 'success'
      case 'DIGIT':
        return 'warning'
      case 'FAIL':
        return 'error'
      case 'FIX':
        return 'info'
      case 'IMGERR':
        return 'error'
    }
  }
  const rows = _.map(imageList, (el, i) => {
    return {
      id: i + 1,
      checkDecimalValue: el?.checkDecimalValue,
      checkValue: el?.checkValue,
      createAt: el?.createAt,
      fileName: el?.fileName,
      filePath: el?.filePath,
      idxDate: el?.idxDate,
      imei: el?.imei,
      rotate: el?.rotate,
      processStatus: el?.processStatus,
      updateAt: el?.updateAt,
      updateCheckDecimalValue: el?.updateCheckDecimalValue,
      updateCheckValue: el?.updateCheckValue,
    }
  })

  useEffect(() => {
    getImageList()
  }, [data, filter?.startDate, filter?.endDate])

  useEffect(() => {
    const today = dayjs()
    const oneWeekAgo = today.subtract(7, 'day')
    setDate([oneWeekAgo, today])
  }, [])
  useEffect(() => {
    if (data && data.latitude && data.longitude) {
      setTimeout(() => {
        let container = document?.getElementById('map')
        if (container) {
          const map = new window.kakao.maps.Map(container, {
            center: new window.kakao.maps.LatLng(data.latitude, data.longitude),
            mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
            level: 1,
            draggable: false,
            scrollwheel: false,
            zoomable: false,
          })
          const mapTypeControl = new window.kakao.maps.MapTypeControl()
          map.addControl(mapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT)
          var markerPosition = new window.kakao.maps.LatLng(data.latitude, data.longitude)

          var marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
          })

          marker.setMap(map)
        }
      }, 300)
    }
  }, [data])
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
        setImageList(null)
      }}
      aria-labelledby={`${window.location.pathname}-label`}
      aria-describedby={`${window.location.pathname}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <>
          {!isMobileDevice() && (
            <div>
              <div>
                <div className=''>
                  <div className={'w-100 d-flex justify-content-between'}>
                    <Chip
                      label={`${data?.imei} 검침 상세정보`}
                      variant={'outlined'}
                      sx={{fontSize: '20px', fontWeight: 'bold'}}
                    />
                    {/*<Button variant={'contained'} onClick={onClose}>*/}
                    <CancelPresentationIcon
                      // className={'point-cursor'}
                      sx={{fontSize: '40px', cursor: 'pointer'}}
                      color={'error'}
                      onClick={() => {
                        onClose()
                        setImageList(null)
                      }}
                    />
                    {/*</Button>*/}
                  </div>

                  <div className=''>
                    {data && (
                      <div className={'d-flex gap-6 col'}>
                        <div className={'col-3'}>
                          <div className={'row mx-0 gap-2'}>
                            <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                              <Paper
                                className={'p-4 border-bottom border-1 border-gray-400 '}
                                square
                              >
                                <h2>단말기 정보</h2>
                              </Paper>
                              <Paper className={'p-4 '} square>
                                <div className={'row gap-2'}>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 IMEI
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.imei}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 S/N
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.serialNumber}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 펌웨어
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.deviceVersion}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 연결시간
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={dayjs(data?.deviceConnectAt).format(
                                        'YYYY-MM-DD HH:mm:ss'
                                      )}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 배터리
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.batteryLevel + '%'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 IMEI
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.imei}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 설치자
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={
                                        !window.location.pathname?.includes('info')
                                          ? '정보없음'
                                          : `${data?.installerId}(${data?.installerName})`
                                      }
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 신호강도
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.signalStrength + 'dBm'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                </div>
                              </Paper>
                            </div>
                            <div
                              id='map'
                              style={{width: '100%', height: 330}}
                              className={'rounded-2 border-gray-400 border border-1'}
                            />
                          </div>
                        </div>
                        <div className={'col-9'}>
                          <div className={'d-flex gap-2 w-100'}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={{start: '시작날짜', end: '마지막 날짜'}}
                            >
                              <DateRangePicker
                                PopperProps={{
                                  style: {
                                    zIndex: 9999,
                                  },
                                }}
                                value={date}
                                calendars={3}
                                mask='____/__/__'
                                inputFormat={'YYYY/MM/DD'}
                                onChange={(newValue) => {
                                  setDate(newValue)
                                  setFilter({
                                    ...filter,
                                    startDate: newValue[0]?.format('YYYYMMDD'),
                                    endDate: newValue[1]?.format('YYYYMMDD'),
                                  })
                                }}
                                renderInput={(startProps, endProps) => {
                                  return (
                                    <div className={'d-flex gap-3'}>
                                      <TextField {...startProps} autoComplete={'off'} />
                                      <ArrowForwardIcon className={'align-self-center'} />
                                      <TextField {...endProps} autoComplete={'off'} />
                                    </div>
                                  )
                                }}
                              />
                            </LocalizationProvider>
                            <Button
                              variant={'contained'}
                              color={'error'}
                              onClick={async () => {
                                const today = dayjs()
                                const oneWeekAgo = today.subtract(7, 'day')
                                setFilter({
                                  ...filter,
                                  startDate: dayjs().subtract(7, 'day').format('YYYYMMDD'),
                                  endDate: dayjs().format('YYYYMMDD'),
                                })
                                setDate([oneWeekAgo, today])
                              }}
                            >
                              <RotateLeftTwoToneIcon sx={{width: 24, height: 24}} />
                            </Button>
                            <Button variant={'contained'} onClick={() => getImageList()}>
                              <SearchIcon sx={{width: 24, height: 24}} />
                            </Button>
                            <Button
                              variant={'outlined'}
                              color={'success'}
                              startIcon={<ListAltIcon sx={{width: 24, height: 24}} />}
                              onClick={() => getDownloadData()}
                            >
                              Download
                            </Button>
                          </div>
                          <div className={'ps-1'} style={{height: '330px'}}>
                            {imageList && (
                              <CustomerDetailChart
                                className={'w-100 px-3'}
                                data={data}
                                imageList={imageList}
                                filter={filter}
                              />
                            )}
                          </div>
                          <div className={'pe-3'}>
                            <Box id={'table-content'} sx={{height: 600, width: '100%'}}>
                              <DataGridPro
                                rows={rows}
                                columns={columns}
                                rowHeight={110}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[10, 20, 60, 100]}
                                disableColumnMenu
                                pagination
                                loading={imageList === null}
                              />
                            </Box>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMobileDevice() && (
            <div className='w-100'>
              <div className={'w-100 d-flex justify-content-between'}>
                <Chip
                  label={`${data?.imei} 검침 상세정보`}
                  variant={'outlined'}
                  sx={{fontSize: '14px', fontWeight: 'bold'}}
                />
                {/*<Button variant={'contained'} onClick={onClose}>*/}
                <CancelPresentationIcon
                  // className={'point-cursor'}
                  sx={{fontSize: '40px', cursor: 'pointer'}}
                  color={'error'}
                  onClick={onClose}
                />
                {/*</Button>*/}
              </div>

              <div className=''>
                {data && (
                  <div className={'row gap-6 col'}>
                    <div className={'col'}>
                      <div className={'row mx-0 gap-2'}>
                        <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                          <Paper className={'p-4 border-bottom border-1 border-gray-400 '} square>
                            <h2>단말기 정보</h2>
                          </Paper>
                          <Paper className={'p-4 '} square>
                            <div className={'row gap-2'}>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 IMEI
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.imei}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 S/N
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.serialNumber}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 펌웨어
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.deviceVersion}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 연결시간
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={dayjs(data?.deviceConnectAt).format('YYYY-MM-DD HH:mm:ss')}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 배터리
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.batteryLevel + '%'}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 단말기 IMEI
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.imei}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 설치자
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={
                                    !window.location.pathname?.includes('info')
                                      ? '정보없음'
                                      : `${data?.installerId}(${data?.installerName})`
                                  }
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                              <div
                                className={'row w-100 justify-content-between align-items-center'}
                              >
                                <h2 className={'d-flex align-items-center gap-2'}>
                                  <DoubleArrowIcon /> 신호강도
                                </h2>
                                <TextField
                                  variant={'outlined'}
                                  className={'mx-2 bg-gray-300'}
                                  value={data?.signalStrength + 'dBm'}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div
                          id='map'
                          style={{width: '100%', height: 330}}
                          className={'rounded-2 border-gray-400 border border-1'}
                        />
                      </div>
                    </div>
                    <div className={'col'}>
                      <div className={'d-flex gap-2 w-100'}>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          localeText={{start: '시작날짜', end: '마지막 날짜'}}
                        >
                          <DateRangePicker
                            PopperProps={{
                              style: {
                                zIndex: 9999,
                              },
                            }}
                            value={date}
                            calendars={3}
                            mask='____/__/__'
                            inputFormat={'YYYY/MM/DD'}
                            onChange={(newValue) => {
                              setDate(newValue)
                              setFilter({
                                ...filter,
                                startDate: newValue[0]?.format('YYYYMMDD'),
                                endDate: newValue[1]?.format('YYYYMMDD'),
                              })
                            }}
                            renderInput={(startProps, endProps) => {
                              return (
                                <div className={'d-flex gap-3'}>
                                  <TextField {...startProps} autoComplete={'off'} />
                                  <ArrowForwardIcon className={'align-self-center'} />
                                  <TextField {...endProps} autoComplete={'off'} />
                                </div>
                              )
                            }}
                          />
                        </LocalizationProvider>
                        <Button
                          variant={'contained'}
                          color={'error'}
                          onClick={async () => {
                            const today = dayjs()
                            const oneWeekAgo = today.subtract(7, 'day')
                            setFilter({
                              ...filter,
                              startDate: dayjs().subtract(7, 'day').format('YYYYMMDD'),
                              endDate: dayjs().format('YYYYMMDD'),
                            })
                            setDate([oneWeekAgo, today])
                          }}
                        >
                          <RotateLeftTwoToneIcon sx={{width: 24, height: 24}} />
                        </Button>
                        <Button variant={'contained'} onClick={() => getImageList()}>
                          <SearchIcon sx={{width: 24, height: 24}} />
                        </Button>
                      </div>
                      <div className={'ps-1'} style={{height: '330px'}}>
                        {imageList && (
                          <CustomerDetailChart
                            className={'w-100 px-3'}
                            data={data}
                            imageList={imageList}
                            filter={filter}
                          />
                        )}
                      </div>
                      <div className={'pe-3'}>
                        <Box id={'table-content'} sx={{height: 600, width: '100%'}}>
                          <DataGridPro
                            rows={rows}
                            columns={columns}
                            rowHeight={90}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 60, 100]}
                            disableColumnMenu
                            pagination
                            loading={imageList === null}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      </Box>
    </Modal>
  )
}
export default DeviceTargetImageListModal
