import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../assets/ts/components'
import {AsideMenuMain} from './AsideMenuMain'
import AsideStatusCard from './AsideStatusCard'
// import AsideStatusCard from './AsideStatusCard'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
  }, [pathname])
  return (
    <>
      <div
        id='kt_aside_menu_wrapper'
        ref={scrollRef}
        className='hover-scroll-overlay-y px-2 my-5 my-lg-5'
        data-kt-scroll='true'
        style={{
          height: 'calc(100vh - 580px)',
          overflowY: 'auto',
          position: 'relative',
          paddingBottom: '20px',
        }}
      >
        <div
          id='kt_aside_menu'
          data-kt-menu='true'
          className='menu menu-column menu-title-gray-800 menu-state-title-primary'
        >
          <AsideMenuMain />
        </div>
      </div>

      <div
        style={{
          position: 'fixed',
          width: '260px',
          height: '260px',
          bottom: '20px',
          zIndex: 10,
        }}
      >
        <AsideStatusCard />
      </div>
    </>
  )
}

export {AsideMenu}
