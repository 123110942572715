import HelpIcon from '@mui/icons-material/Help'
import RefreshIcon from '@mui/icons-material/Refresh'
import {Button, Chip, Fade, FormControl, InputLabel, MenuItem, Select, Tooltip} from '@mui/material'
import Paper from '@mui/material/Paper'
import {styled} from '@mui/material/styles'
import {DataGridPro, GridColDef} from '@mui/x-data-grid-pro'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {HyphensOfficeHandler} from 'utils/const/globalConst'
import {envBackHost} from '../../GLOBAL/envVariables'
import {axiosWithAuth} from '../../helper/axiosWithAuth'
import {resultAlert, validationAlert} from '../../utils/CustomAlert/Alert'
const ListItem = styled('li')(({theme}) => ({
  margin: theme.spacing(0.5),
}))

const ExcelFormEditModal = (props: any) => {
  const [chipData, setChipData] = React.useState<any[]>([])
  const [excelList, setExcelList] = useState<any>(null)
  const [officeExcelList, setOfficeExcelList] = useState<any>(null)
  const officeInfo = useSelector((state: any) => state.officeInfo)
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const handleDelete = (chipToDelete: any) => () => {
    setChipData((chips: any) => chips.filter((chip: any) => chip.idx !== chipToDelete.idx))
  }
  const handleAdd = (e: any) => {
    const label = _.find(excelList, (el: any) => el?.idx === e.target.value)?.label
    setChipData((chips: any) => [...chips, {idx: e.target.value, label: label}])
  }
  const filteredExcelList = excelList?.filter((el: any) => {
    // chipData 내에 동일한 idx 값을 가진 요소가 있는지 확인
    return !chipData.some((chip: any) => chip?.idx === el?.idx)
  })
  const getExcelForm = () => {
    axiosWithAuth()
      .get(`${envBackHost}/excel/form?url=${window.location.pathname}`)
      .then((res) => {
        // console.log(res.data.res)
        setExcelList(res.data.res)
      })
  }
  const columns: GridColDef[] = _.map(chipData, (el, i) => ({
    field: el.label,
    headerName: el.label,
    width: el?.label?.length <= 3 ? 100 : el?.label?.length * 24,
    headerAlign: 'center',
  }))
  const rows = _.map(chipData, (el, i) => {
    return {
      id: i,
      ...el,
    }
  })
  const swapArrayElements = (arr: any[], index1: number, index2: number) => {
    ;[arr[index1], arr[index2]] = [arr[index2], arr[index1]]
  }
  const handleColumnOrderChange = (params: any) => {
    const oldIndex = params.oldIndex
    const targetIndex = params.targetIndex

    const newChipData = [...chipData]
    swapArrayElements(newChipData, oldIndex, targetIndex)
    setChipData(newChipData)
  }
  const getOfficeExcelForm = () => {
    axiosWithAuth()
      .get(`${envBackHost}/${userInfo?.userId}/excel/form?url=${window.location.pathname}`)
      .then((res) => {
        setChipData(res.data.res)
      })
  }

  const postExcelForm = async () => {
    if (chipData?.length === 0) {
      await validationAlert('선택된 항목이 없습니다.', 'warning')
      return
    }
    axiosWithAuth()
      .post(`${envBackHost}/${userInfo?.userId}/excel/order?url=${window.location.pathname}`, {
        excelOrderList: chipData,
      })
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          getExcelForm()
          document.getElementById('excel-close-btn')?.click()
        }
      })
  }
  useEffect(() => {
    getExcelForm()
    getOfficeExcelForm()
  }, [])
  return (
    <>
      <div
        className='modal fade'
        id='ExcelFormEditModal'
        tabIndex={-1}
        aria-labelledby='ExcelFormEditModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered modal-xl  p-3'>
          <div className='modal-content  rounded-2'>
            <div className='modal-header'>
              <h2 className='modal-title' id='ExcelFormEditModalLabel'>
                엑셀 양식지정
              </h2>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                id={'allocation-close-btn'}
              ></button>
            </div>
            <div className='modal-body'>
              <div className={'d-flex gap-2 my-2 w-100'}>
                <FormControl className={'col'}>
                  <InputLabel id='demo-simple-select-label'>엑셀 열 추가</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='customer-target'
                    label='엑셀 열 추가'
                    onChange={(e: any) => {
                      handleAdd(e)
                    }}
                  >
                    {filteredExcelList && filteredExcelList.length > 0 ? (
                      _.map(filteredExcelList, (el: any, i: number) => {
                        // 수용가 정보(하이픈에 해당하는 사업소인경우만 하이픈 선택 출력)
                        if (el?.idx === 37 && HyphensOfficeHandler(officeInfo?.value) === false) {
                          return false
                        }
                        return (
                          <MenuItem value={el?.idx} key={i}>
                            {el?.label}
                          </MenuItem>
                        )
                      })
                    ) : (
                      <MenuItem value='' disabled>
                        선택할 수 있는 항목을 모두 선택하였습니다.
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <Button
                  variant={'contained'}
                  className={'mx-2 d-flex gap-2'}
                  onClick={() => getOfficeExcelForm()}
                >
                  <RefreshIcon />
                </Button>
              </div>
              {chipData?.length > 0 && (
                <>
                  <Paper
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      // p: 0.5,
                      m: 0,
                    }}
                    component='ul'
                  >
                    {chipData?.map((data: any) => {
                      return (
                        <ListItem key={data?.idx}>
                          <Chip
                            label={data?.label}
                            onDelete={handleDelete(data)}
                            sx={{fontSize: '16px'}}
                          />
                        </ListItem>
                      )
                    })}
                  </Paper>
                  <div className={'my-5'}>
                    <div className={'d-flex gap-1 mb-2'}>
                      <span className={'m-0 fs-3 fw-bold'}>Excel 양식 미리보기</span>
                      <Tooltip
                        title={
                          <span>
                            아래 엑셀 미리보기 열을 드래그&드랍 하여 열의 순서를 변경할 수 있습니다.
                          </span>
                        }
                        TransitionComponent={Fade}
                        TransitionProps={{timeout: 600}}
                        placement={'right-start'}
                        arrow={true}
                      >
                        <HelpIcon color={'primary'} sx={{fontSize: '24px'}} className={''} />
                      </Tooltip>
                      <span className={'text-danger fw-bold align-self-end'}>
                        도움말을 확인해주세요.
                      </span>
                    </div>
                    <div style={{width: '100%', height: '123px'}}>
                      <DataGridPro
                        columns={columns}
                        rows={rows}
                        disableColumnMenu
                        hideFooterRowCount={true}
                        pagination={false}
                        onColumnOrderChange={handleColumnOrderChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className='modal-footer gap-5'>
              <Button
                variant={'contained'}
                className={'p-5'}
                color={'error'}
                id={'excel-close-btn'}
                type={'button'}
                data-bs-dismiss='modal'
              >
                취소
              </Button>
              <Button
                variant={'contained'}
                className={'p-5'}
                color={'success'}
                type={'button'}
                disabled={chipData?.length === 0}
                onClick={() => {
                  postExcelForm()
                }}
              >
                저장
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/*<ExcelDownLoadModal type={type} />*/}
    </>
  )
}

export default ExcelFormEditModal
