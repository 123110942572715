import {Chip, FormControl, MenuItem, Select, TextField, Tooltip} from '@mui/material'
import {GridColDef} from '@mui/x-data-grid-pro'
import {defaultImage, noImage} from 'assets/imageDeclear'
import dayjs from 'dayjs'
import {envImagePath} from 'GLOBAL/envVariables'

export const wimsColumns = (stateValue: any, setStateValue: any): GridColDef[] => {
  return [
    {
      field: 'check_date',
      headerName: '납기마감일',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가 번호',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 350,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
          </div>
        )
      },
    },
    {
      field: 'city_code',
      headerName: '구코드',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'resource_code',
      headerName: '자원구분코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'sub_number',
      headerName: '부번호',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'now_check_code',
      headerName: '당월검침코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.check_value === null || params.row.check_value === 'null') {
          params.row.check_value = params.row.check_value_default
        }
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].check_value = e.target.value
              setStateValue(updateValue)
            }}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
          />
        )
      },
    },
    {
      field: 'now_check_type',
      headerName: '검침방법구분코드',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'trsDateInfo',
      headerName: '변경시간',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.trsdat}</span>
            <span>{params.row.trstim}</span>
          </div>
        )
      },
    },
    {
      field: 'trsInfo',
      headerName: 'IP',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <span>{params.row.trsusr}</span>
            <span>{params.row.ip}</span>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}

export const blueWaterColumns = (stateValue: any, setStateValue: any): GridColDef[] => {
  return [
    {
      field: 'check_date',
      headerName: '납기마감일',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '고객 번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customer_name',
      headerName: '이름',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'address_Info',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row'}>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'city_code',
      headerName: '시군코드',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'now_check_date',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'check_value',
      headerName: '검침정보',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].check_value = e.target.value
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_check_code',
      headerName: '검침코드',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-75 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '1' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...stateValue]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setStateValue(updateValue)
                }}
              >
                <MenuItem value='1'>정상</MenuItem>
                <MenuItem value='2'>점검필요</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}
//SAEOL1 칼럼 목록 수정 - 새올에서 받아온 값들중에서 특정 칼럼만 VISIBLE FALSE로
export const saeol1Columns = (stateValue: any, setStateValue: any): GridColDef[] => {
  return [
    {
      field: 'check_date',
      headerName: '검침년월',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가번호',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 500,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-start'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].check_value = e.target.value
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'check_code',
      headerName: '당월검침코드',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-100 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '00' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...stateValue]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setStateValue(updateValue)
                }}
              >
                <MenuItem value='00'>00 (정상)</MenuItem>
                <MenuItem value='01'>01 (회전)</MenuItem>
                <MenuItem value='02'>02 (교체)</MenuItem>
                <MenuItem value='03'>03 (인정)</MenuItem>
                <MenuItem value='04'>04 (부동)</MenuItem>
                <MenuItem value='05'>05 (누수)</MenuItem>
                <MenuItem value='06'>06 (중지)</MenuItem>
                <MenuItem value='07'>07 (미검침)</MenuItem>
                <MenuItem value='08'>08 (자가검침)</MenuItem>
                <MenuItem value='09'>09 (공업용수)</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phoneNumber',
      headerName: '연락처',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}

export const crebizColumns = (stateValue: any, setStateValue: any): GridColDef[] => {
  return [
    {
      field: 'check_date',
      headerName: '납기마감일',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '고객 번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customer_name',
      headerName: '이름',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'address_Info',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row'}>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
          </div>
        )
      },
    },
    {
      field: 'bef_check_value',
      headerName: '전월 지침값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.bef_check_value === null || params?.row.bef_check_value === 'null'
                ? ''
                : Number(params?.row.bef_check_value)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].bef_check_value = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'bef_used',
      headerName: '전월 사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.bef_used === null || params?.row.bef_used === 'null'
                ? ''
                : Number(params?.row.bef_used)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].bef_used = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_check_date',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },

    {
      field: 'check_value',
      headerName: '검침정보',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },

    {
      field: 'updateCheckValue',
      headerName: '당월 지침값',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.check_value === null || params?.row.check_value === 'null'
                ? ''
                : Number(params?.row.check_value)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].check_value = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_used',
      headerName: '당월 사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={
              params?.row.now_used === null || params?.row.now_used === 'null'
                ? ''
                : Number(params?.row.now_used)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].now_used = e.target.value
              updateValue[params.row.id - 1].filter3 = 'true'
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_check_code',
      headerName: '검침코드',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-75 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '001' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...stateValue]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  updateValue[params.row.id - 1].filter3 = 'true'
                  setStateValue(updateValue)
                }}
              >
                <MenuItem value='001'>001 : 정상</MenuItem>
                <MenuItem value='002'>002 : 개조</MenuItem>
                <MenuItem value='003'>003 : 고장</MenuItem>
                <MenuItem value='004'>004 : 동파</MenuItem>
                <MenuItem value='005'>005 : 노후</MenuItem>
                <MenuItem value='006'>006 : 파손</MenuItem>
                <MenuItem value='007'>007 : 망실</MenuItem>
                <MenuItem value='008'>008 : 검증</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
  ]
}

export const saeolColumns = (stateValue: any, setStateValue: any): GridColDef[] => {
  return [
    {
      field: 'check_date',
      headerName: '검침년월',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'customer_number',
      headerName: '수용가번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
            <span className={'text-danger text-center'}>
              {params.row.customer_status === null || params.row.customer_status === 'null'
                ? null
                : params.row.customer_status === 'as'
                ? 'A/S'
                : params.row.customer_status === 'move'
                ? '이전 철거'
                : '철거'}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 400,
      align: 'left',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className={'row text-start'}>
            <span className={''}>{params.row.customer_name}</span>
            {params.row.address_road && (
              <span>
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}{' '}
              </span>
            )}
            {params.row.address_number && (
              <span>
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}{' '}
              </span>
            )}
            {/* {params.row.addressDetail && (
              <span>
                <strong style={{color: 'rgba(86,31,13,0.7)'}}>상세주소 </strong> :{' '}
                {params.row.addressDetail}{' '}
              </span>
            )} */}
          </div>
        )
      },
    },
    {
      field: 'bef_check_code',
      headerName: '전월 검침코드',
      width: 140,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'bef_check_value',
      headerName: '전월 지침',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'bef_used',
      headerName: '전월 사용량',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>
            <span>{dayjs(params.row.idx_date).format('YYYY-MM-DD')}</span>
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 350,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '15px'}}
                >
                  {params.row.rotate > 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '17px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '17px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '17px'}} />
                  )}
                </div>
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={144}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            defaultValue={Number(params.row.check_value)}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const updateValue = [...stateValue]
              updateValue[params.row.id - 1].check_value = e.target.value
              setStateValue(updateValue)
            }}
          />
        )
      },
    },
    {
      field: 'now_used',
      headerName: '당월사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      // renderCell: (params) => {
      //   return (
      //     <TextField
      //       variant='outlined'
      //       defaultValue={Number(params.row.check_value)}
      //       onChange={(e) => {
      //         const updateValue = [...checkMeterData]
      //         updateValue[params.row.id - 1].check_value = e.target.value
      //         setCheckMeterData(updateValue)
      //       }}
      //     />
      //   )
      // },
    },
    {
      field: 'check_code',
      headerName: '당월검침코드',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <div className='w-100 text-center'>
            <FormControl fullWidth>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '00' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...stateValue]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  setStateValue(updateValue)
                }}
              >
                <MenuItem value='00'>00 (정상)</MenuItem>
                <MenuItem value='01'>01 (회전)</MenuItem>
                <MenuItem value='02'>02 (교체)</MenuItem>
                <MenuItem value='03'>03 (인정)</MenuItem>
                <MenuItem value='04'>04 (부동)</MenuItem>
                <MenuItem value='05'>05 (누수)</MenuItem>
                <MenuItem value='06'>06 (중지)</MenuItem>
                <MenuItem value='07'>07 (미검침)</MenuItem>
                <MenuItem value='08'>08 (자가검침)</MenuItem>
                <MenuItem value='09'>09 (공업용수)</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phoneNumber',
      headerName: '연락처',
      width: 170,
      align: 'center',
      headerAlign: 'center',
    },
  ]
}

export const saeolEumseongColumns = (
  stateValue: any,
  setStateValue: any,
  officeInfo: any
): GridColDef[] => {
  return [
    {
      field: 'check_date',
      headerName: '검침년월',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'customer_number',
      headerName: '수용가번호',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2) => {
        return parseInt(v1, 10) - parseInt(v2, 10)
      },
      renderCell: (params) => {
        return (
          <div className={'row'}>
            <span className={'text-hover-danger cursor-pointer text-primary fw-bold'}>
              {params.row.customer_number}
            </span>
          </div>
        )
      },
    },
    {
      field: 'customerInfo',
      headerName: '주소',
      width: 320,
      align: 'left',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            className='row text-start'
            style={{width: '100%', display: 'flex', flexDirection: 'column'}}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '100%',
              }}
            >
              {params.row.customer_name}
            </span>
            {params.row.address_road && (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  maxWidth: '100%',
                }}
              >
                <strong style={{color: '#104f98'}}>도로명 </strong> : {params.row.address_road}
              </span>
            )}
            {params.row.address_number && (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  maxWidth: '100%',
                }}
              >
                <strong style={{color: '#f37005'}}>지번 </strong> : {params.row.address_number}
              </span>
            )}
          </div>
        )
      },
    },
    {
      field: 'bef_check_code',
      headerName: '검침코드',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2) => {
        const value1 = v1 === null ? -1 : parseInt(v1, 10)
        const value2 = v2 === null ? -1 : parseInt(v2, 10)

        return value1 - value2
      },
    },
    {
      field: 'bef_check_value',
      headerName: '지침',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2) => {
        const value1 = v1 === null ? -1 : parseInt(v1, 10)
        const value2 = v2 === null ? -1 : parseInt(v2, 10)

        return value1 - value2
      },
    },
    {
      field: 'bef_used',
      headerName: '사용량',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (v1, v2) => {
        const value1 = v1 === null ? -1 : parseInt(v1, 10)
        const value2 = v2 === null ? -1 : parseInt(v2, 10)

        return value1 - value2
      },
    },
    {
      field: 'three_month_average',
      headerName: '3개월 평균사용량',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        if (
          params.row.three_bef_used !== '' &&
          params.row.two_bef_used !== '' &&
          params.row.bef_used !== ''
        ) {
          const average =
            (Number(params.row.three_bef_used) +
              Number(params.row.two_bef_used) +
              Number(params.row.bef_used)) /
            3
          return (
            <div className='row'>
              <span>{average?.toFixed(2).replace('.00', '')}</span>
            </div>
          )
        } else {
          return <div></div>
        }
      },
    },

    {
      field: 'checkInfo',
      headerName: '검침일',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const checkDate = params?.row?.check_date
        const checkDay = params?.row?.check_day
        const combinedDate = dayjs(`${checkDate}${checkDay}`).format('YYYY-MM-DD')
        const idxDate = dayjs(params.row.idx_date).format('YYYY-MM-DD')
        return (
          <div className={'row text-center'}>
            <div className={'d-flex justify-content-center'}>
              <span>
                {params.row.check_value_default === '' ? (
                  <span className={'text-danger'}>인식불가</span>
                ) : (
                  params.row.check_value_default
                )}
              </span>
              <span>
                {params.row.check_decimal_value === '' ||
                params.row.check_decimal_value === null ||
                params.row.check_decimal_value === 'null'
                  ? ''
                  : '.' + params.row.check_decimal_value?.split('.')[1]}
              </span>
            </div>

            {combinedDate !== idxDate ? (
              <Tooltip title='마지막 검침일자와 다릅니다'>
                <span className='text-primary'>{idxDate}</span>
              </Tooltip>
            ) : (
              <span>{idxDate}</span>
            )}
          </div>
        )
      },
    },
    {
      field: 'image',
      headerName: '이미지',
      width: 300,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.device_type !== 'analog' ? (
              <img className={'cursor-pointer'} src={defaultImage} />
            ) : (
              <div className={'row'}>
                {/* <div
                  className={'d-flex gap-1 justify-content-end'}
                  style={{position: 'relative', top: '28px'}}
                >
                  {params.row.rotate != 0 && (
                    <Chip
                      label={`${params.row.rotate}° `}
                      color='primary'
                      sx={{fontSize: '14px'}}
                    />
                  )}
                  {params.row.recognized_type === null || params.row.recognized_type === 'null' ? (
                    <Chip label='기계식' color='error' sx={{fontSize: '14px'}} />
                  ) : (
                    <Chip label='디지털' color='warning' sx={{fontSize: '14px'}} />
                  )}
                </div> */}
                <img
                  alt={'device-image'}
                  className={'cursor-pointer'}
                  src={
                    params.row.file_path === null
                      ? noImage
                      : `${envImagePath}${params.row.file_path}`
                  }
                  width={320}
                  height={120}
                  style={{
                    transform:
                      (Number(params.row.rotate) > 90 && Number(params.row.rotate) < 270) ||
                      (Number(params.row.rotate) < -90 && Number(params.row.rotate) > -270)
                        ? `rotate(180deg)`
                        : `rotate(0deg)`,
                  }}
                />
              </div>
            )}
          </>
        )
      },
    },
    {
      field: 'check_code',
      headerName: '당월검침코드',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='w-100 text-center'>
            <FormControl fullWidth variant='standard'>
              <Select
                label=''
                id='check-code-select'
                value={params.row.now_check_code === null ? '00' : params.row.now_check_code}
                onChange={(e) => {
                  const updateValue = [...stateValue]
                  updateValue[params.row.id - 1].now_check_code = e.target.value
                  updateValue[params.row.id - 1].edited_status = 'true'
                  setStateValue(updateValue)
                }}
                sx={{fontWeight: 400, fontSize: '1.5rem'}}
              >
                <MenuItem value='00'>00 (정상)</MenuItem>
                <MenuItem value='01'>01 (회전)</MenuItem>
                <MenuItem value='02'>02 (교체)</MenuItem>
                <MenuItem value='03'>03 (인정)</MenuItem>
                <MenuItem value='04'>04 (부동)</MenuItem>
                <MenuItem value='05'>05 (누수)</MenuItem>
                <MenuItem value='06'>06 (중지)</MenuItem>
                <MenuItem value='07'>07 (미검침)</MenuItem>
                <MenuItem value='08'>08 (자가검침)</MenuItem>
                <MenuItem value='09'>09 (공업용수)</MenuItem>
              </Select>
            </FormControl>
          </div>
        )
      },
    },
    {
      field: 'updateCheckValue',
      headerName: '당월지침',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <TextField
            variant='outlined'
            value={
              params?.row?.check_value !== null
                ? Number(params?.row?.check_value)
                : Number(params?.row?.check_value_default)
            }
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === 'a') {
                e.stopPropagation()
              }
              if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                e.stopPropagation()
              }
            }}
            onChange={(e) => {
              const inputValue = Number(e.target.value)
              if (!isNaN(inputValue)) {
                const updateValue = [...stateValue]
                updateValue[params.row.id - 1].check_value = inputValue
                updateValue[params?.row.id - 1].now_used =
                  Number(inputValue) - params?.row?.bef_check_value
                updateValue[params.row.id - 1].edited_status = 'true'
                setStateValue(updateValue)
              }
            }}
            sx={{input: {fontWeight: 400, fontSize: '1.5rem'}}}
          />
        )
      },
    },
    {
      field: 'now_used',
      headerName: '당월사용량',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const minThreshold = 0
        return (
          <FormControl fullWidth sx={{m: 1, maxWidth: 200}}>
            <TextField
              variant='outlined'
              value={
                stateValue[params.row.id - 1]?.now_used === null
                  ? stateValue[params.row.id - 1].check_value_default -
                    stateValue[params.row.id - 1].bef_check_value
                  : stateValue[params.row.id - 1]?.now_used
              }
              onChange={(e) => {
                const inputValue = Number(e.target.value)
                if (!isNaN(inputValue)) {
                  const updateValue = [...stateValue]
                  updateValue[params.row.id - 1].now_used = inputValue
                  updateValue[params.row.id - 1].edited_status = 'true'
                  setStateValue(updateValue)
                }
              }}
              error={
                (stateValue[params.row.id - 1]?.now_used === null
                  ? stateValue[params.row.id - 1].check_value_default -
                    stateValue[params.row.id - 1].bef_check_value
                  : stateValue[params.row.id - 1]?.now_used) < minThreshold
              }
              sx={{
                input: {
                  fontWeight: 400,
                  fontSize: '1.5rem',
                  maxWidth: '150px',
                },
              }}
            />
          </FormControl>
        )
      },
    },
    {
      field: 'leak',
      headerName: '누수 의심',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      description: '당월 사용량이 전월사용량 대비 20% 이상일 경우, 누수 의심 표기',
      sortable: false,

      renderCell: (params) => {
        const now_used = params.row?.now_used
        const check_value_default = params.row?.check_value_default
        const bef_check_value = params.row?.bef_check_value
        const bef_used = params.row?.bef_used
        const leakageValue = Number(bef_used) + Number(bef_used) * 0.2
        return (
          <div className='row'>
            {(now_used === null
              ? check_value_default - bef_check_value > leakageValue
              : now_used > leakageValue) && <span className='fw-bold'>이상 증가</span>}
          </div>
        )
      },
    },
    {
      field: 'edited_status',
      headerName: '상태',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        const minThreshold = 0

        return (
          <div className=''>
            {(stateValue[params.row.id - 1]?.now_used === null
              ? stateValue[params.row.id - 1].check_value_default -
                stateValue[params.row.id - 1].bef_check_value
              : stateValue[params.row.id - 1]?.now_used) < minThreshold
              ? '점검필요'
              : '정상'}
            {params.row?.edited_status === 'true' && <span className='fw-bold'>(수정)</span>}
          </div>
        )
      },
    },
    {
      field: 'check_user',
      headerName: '정보수정자',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return <div className=''></div>
      },
    },
  ]
}
