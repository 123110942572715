import {Step, StepLabel, Stepper} from '@mui/material'
import Box from '@mui/material/Box'
import {serverLocalName} from 'GLOBAL/envVariables'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const ChargeHeader = (props: any) => {
  const serverName = serverLocalName
  const {selectStep} = props
  const navigate = useNavigate()
  const officeInfo = useSelector((state: any) => state?.officeInfo)
  const steps = (server: String) => {
    switch (server) {
      case 'dev':
      case 'boseong':
      case 'eumseong':
        return ['납기일 선택', '요금연계 진행']
      default:
        return ['요금프로그램 선택', '납기일 선택', '요금연계 진행']
    }
  }
  const activeStepsHandler = (server: String) => {
    if (server === 'eumseong') {
      return selectStep.step2 ? 1 : 0
    }
    if (server === 'boseong') {
      return selectStep.step2 ? 1 : 0
    }
    if (server === 'dev') {
      return selectStep.step2 ? 1 : 0
    }

    if (selectStep.step1 && selectStep.step2) {
      return 2
    } else if (selectStep.step1) {
      return 1
    } else {
      return 0
    }
  }

  return (
    <>
      <div className='card mb-5' id='charge-service'>
        <div className={'card-body align-self-center w-100 p-2 pt-3'}>
          <Box sx={{width: '100%'}} className={''}>
            <Stepper activeStep={activeStepsHandler(serverName)} alternativeLabel>
              {steps(serverName).map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>
      </div>
    </>
  )
}

export default ChargeHeader
