import {DataGridPro} from '@mui/x-data-grid-pro'
import {envBackHost, serverLocalName} from 'GLOBAL/envVariables'
import dayjs from 'dayjs'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import fileDownload from 'js-file-download'
import {
  customerLeakageColumns,
  customerLeakageColumnsBongHwa,
} from 'pages/customer/dataConfig/declearColumns'
import {customerLeakageFilter} from 'pages/customer/dataConfig/declearFilters'
import {customerLeakageRows} from 'pages/customer/dataConfig/declearRows'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import {resultAlert} from 'utils/CustomAlert/Alert'
import {getNewLeakList, getNewLeakListCount} from 'utils/apiCalling/api'
import {leakModelHandler} from 'utils/const/globalConst'
import CustomPagination from 'utils/designSytem/CustomPagination'
import MeterariumPageLayout from 'utils/designSytem/MeterariumPageLayout'
import {scrollTop} from 'utils/func/justUtils'
import {customerLeakageFilterType, getLeakListCallType} from 'utils/interface/customerType'
import {reduxType} from 'utils/interface/reduxType'
import {toolTipText} from 'utils/toolTips/toolTipText'
import {LeakDetailModal} from '../modules/LeakDetailModal'

const CustomerLeakage = (props: any) => {
  const leakOptionsDataInit: any = {
    officeName: '',
    leakUseCycle: '',
    leakUseStandard: '',
    leakUseUnit: '',
  }
  const customerInfoFilter = {
    searchOption: {
      customerNumber: false,
      customerName: false,
      address: false,
      managerName: false,
      memo: false,
      serialNumber: false,
      installerName: false,
    },
    searchData: '',
    deviceStatus: 'all',
    efosStatus: 'all',
    recognizedType: 'all',
    deviceType: 'all',
    batteryStatus: 'all',
    rowPerPage: 30,
    page: 1,
  }

  const [pageSize, setPageSize] = useState<number>(10)
  const [leakInfo, setLeakInfo] = useState<any>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const whatInfo = useSelector((state: any) => state)
  const officeInfo = useSelector((state: reduxType) => state.officeInfo)
  console.log('off', officeInfo)
  const cooperInfo = useSelector((state: reduxType) => state.cooperInfo)
  const [listLoading, setlistLoading] = useState<boolean>(false)
  const [countLoading, setCountLoading] = useState<boolean>(true)
  const [leakCount, setLeakCount] = useState<number>(0)
  const [filter, setFilter] = useState<any>({
    ...customerLeakageFilter,
    flag: false,
  })
  const [leakOptionsData, setLeakOptionData] = useState<any>(leakOptionsDataInit)
  const [leakModalOpen, setLeakModalOpen] = useState<boolean>(false)

  const totalCountCall = () => {
    getNewLeakListCount(officeInfo, cooperInfo, filter, {
      success: (res: getLeakListCallType) => {},
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setCountLoading(loading)
        if (!listLoading) {
          setlistLoading(loading)
        }
      },
      count: (count: number) => {
        setLeakCount(count)
      },
    })
  }

  const getLeakListCall = () => {
    {
      totalCount == 0 &&
        axiosWithAuth()
          .get(
            `${envBackHost}/${officeInfo?.value === 'yit' ? 'all' : officeInfo?.value}/${
              cooperInfo?.value
            }/customer/info`,
            {
              params: customerInfoFilter,
            }
          )
          .then((res) => {
            setTotalCount(res.data.count)
          })
    }

    getNewLeakList(officeInfo, cooperInfo, filter, {
      success: (res: getLeakListCallType) => {
        setLeakInfo(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {
        setlistLoading(loading)
      },
      // count: (count: number) => {
      //   setLeakCount(count)
      // },
    })
  }
  const excelDownLoad = (params: customerLeakageFilterType) => {
    getDownloadExcel(params)
  }

  const getDownloadExcel = async (params: customerLeakageFilterType) => {
    await toast
      .promise(
        axiosWithAuth()
          .get(`${envBackHost}/${officeInfo?.value}/${cooperInfo?.value}/customer/leak/excel`, {
            params: params,
            responseType: 'blob',
          })
          .then(async (res) => {
            await resultAlert({resultCode: 0})
            fileDownload(res.data, `${officeInfo?.label}_${filter.date}_누수_의심_수용가.xlsx`)
          })
          .catch(async (e) => {
            await resultAlert({resultCode: 400})
          }),
        {
          pending: '다운로드 중입니다.',
        },
        {
          position: 'top-center',
          autoClose: 1000,
          className: 'fs-3  fw-bold',
          style: {width: '400px'},
        }
      )
      .catch(async (e) => {
        await resultAlert({resultCode: 400})
      })
  }

  const getOfficeDetailInfo = async () => {
    if (officeInfo.value !== 'yit') {
      axiosWithAuth()
        .get(`${envBackHost}/office/${officeInfo.value}`)
        .then((res: any) => {
          let response = res.data.res[0]
          setLeakOptionData({
            ...leakOptionsData,
            officeName: response.officeName,
            leakUseCycle: response.officeCustomerTypeModel[0].leakUseCycle,
            leakUseStandard: response.officeCustomerTypeModel[0].leakUseStandard,
            leakUseUnit: response.officeCustomerTypeModel[0].leakUseUnit,
            leakModalOpen: leakModalOpen,
            setLeakModalOpen: setLeakModalOpen,
            excelDownLoad: excelDownLoad,
          })
        })
    }
  }

  const columns: any =
    leakModelHandler(serverLocalName, officeInfo.value) === '2'
      ? customerLeakageColumnsBongHwa(filter)
      : customerLeakageColumns(filter)
  const rows = customerLeakageRows(leakInfo)

  useEffect(() => {
    scrollTop()
    getLeakListCall()
    if (leakModelHandler(serverLocalName, officeInfo.value) === '2') {
      getOfficeDetailInfo()
    }
  }, [officeInfo, cooperInfo, filter.date, filter.rowPerPage, filter.page])

  useEffect(() => {
    totalCountCall()
  }, [filter.date, officeInfo, cooperInfo])

  return (
    <>
      {leakModelHandler(serverLocalName, officeInfo.value) === '1' && (
        <MeterariumPageLayout
          headerDescription={toolTipText.customerLeakage.description}
          tooltipText={toolTipText.customerLeakage.text}
          filter={filter}
          id={'customer-leakage-temp'}
          setFilter={setFilter}
          searchCallback={getLeakListCall}
          total={totalCount}
          hideSearchOptions={true}
          control={['selectDate']}
          actions={['']}
          // actions={['leak']}
          date={[{date: dayjs().format('YYYYMMDD')}]}
          asValue={leakOptionsData}
          searchOptions={[]}
          checkData={null}
          onDelete={null}
          onCancel={null}
          setTypeName={null}
        >
          <DataGridPro
            rows={rows}
            columns={columns}
            rowHeight={300}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            disableColumnMenu
            rowsPerPageOptions={[10, 30, 50, 70, 100]}
            pagination
            loading={listLoading}
            autoHeight={true}
          />
        </MeterariumPageLayout>
      )}
      {leakModelHandler(serverLocalName, officeInfo.value) === '2' && (
        <>
          <MeterariumPageLayout
            headerDescription={toolTipText.customerLeakage.description}
            tooltipText={toolTipText.customerLeakage.text}
            filter={filter}
            id={'customer-leakage'}
            setFilter={setFilter}
            searchCallback={getLeakListCall}
            total={totalCount}
            errorCount={leakCount}
            hideSearchOptions={true}
            control={['selectDate']}
            actions={['leak']}
            date={[{date: dayjs().format('YYYYMMDD')}]}
            asValue={leakOptionsData}
            searchOptions={[]}
            checkData={null}
            onDelete={null}
            onCancel={null}
            setTypeName={null}
            loading={countLoading && listLoading ? true : false}
          >
            <DataGridPro
              rows={rows}
              columns={columns}
              rowHeight={300}
              disableColumnMenu
              loading={listLoading}
              autoHeight={true}
              paginationMode={'server'}
              hideFooterPagination={true}
              pagination={true}
              hideFooter={true}
              sx={{
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
            />
            <CustomPagination filter={filter} setFilter={setFilter} totalCount={leakCount} />
          </MeterariumPageLayout>
          <LeakDetailModal
            leakOption={leakOptionsData}
            filter={filter}
            open={leakModalOpen}
            setOpen={setLeakModalOpen}
          />
        </>
      )}
    </>
  )
}
export default CustomerLeakage
