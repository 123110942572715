import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import {
  Backdrop,
  Button,
  Chip,
  IconButton,
  InputAdornment,
  Modal,
  Slider,
  TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {envBackHost, envImagePath} from 'GLOBAL/envVariables'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {yMarker} from 'assets/imageDeclear'
import axios from 'axios'
import dayjs from 'dayjs'
import {fabric} from 'fabric'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {useEffect, useRef, useState} from 'react'
import {useDaumPostcodePopup} from 'react-daum-postcode'
import {confirmAlert, normalAlert, resultAlert} from 'utils/CustomAlert/Alert'
import './imageEdit.css'
import './print.css'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: !isMobileDevice() ? '95%' : '90%',
  height: !isMobileDevice() ? '90%' : '90%',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  // border: '2px solid #000',
  borderRadius: '12px',
  boxShadow: 24,
  p: !isMobileDevice() ? 4 : 2,
}
const DeviceDetailInfoModal = (props: any) => {
  const {data, open, onClose, getList} = props
  const cropperRef = useRef<any>(null)
  const [canvas, setCanvas] = useState<any | null>(null)
  const canvasRef = useRef(null)

  const [updateEfosImageInfo, setUpdateEfosImageInfo] = useState<any>(null)
  const [updateEfosInfo, setUpdateEfosInfo] = useState<any>(null)
  const [updateServerCycle, setUpdateServerCycle] = useState<number>(0)
  const [imageLoaded, setImageLoaded] = useState(false)
  // const canvas = new fabric.Canvas('imageEdit')
  const [address, setAddress] = useState<any>({
    latitude: '',
    longitude: '',
  })

  const CURRENT_URL = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
  const openMap = useDaumPostcodePopup(CURRENT_URL)
  const handleComplete = async (data: any) => {
    const location = await getAddressCoordinates(data.roadAddress)
    console.log(location)
    if (location) {
      setAddress({
        latitude: location.y,
        longitude: location.x,
      })
    }
  }
  const onClickHandler = () => {
    openMap({onComplete: handleComplete})
  }

  const getAddressCoordinates = async (address: any) => {
    const apiKey = 'd2462714d2612ee54962c634ee9ac1c1'
    const endpoint = `https://dapi.kakao.com/v2/local/search/address.json`

    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `KakaoAK ${apiKey}`,
        },
        params: {
          query: address,
        },
      })

      if (response.data.documents && response.data.documents.length > 0) {
        return {
          x: response.data.documents[0].x,
          y: response.data.documents[0].y,
        }
      } else {
        throw new Error('No documents found')
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error)
      return null
    }
  }
  const updateServerCycleHandler = async () => {
    if (Number(updateServerCycle) === 0) {
      await normalAlert('서버 주기는 0이면 안됩니다.')
      return
    }
    if (updateServerCycle === data.serverCycle) {
      await normalAlert('변경 사항이 없습니다.')
      return
    }

    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/deviceinfo`, {
          imei: data.imei,
          latitude: data.latitude,
          longitude: data.longitude,
          temperature: data.temperature,
          serverCycle: updateServerCycle,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }

  const updateEfosInfoHandler = async (type: string) => {
    if (updateEfosInfo === null) {
      await normalAlert('변경된 데이터가 없습니다.')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      const requestData = {[type]: updateEfosInfo[type], imei: data.imei}
      axiosWithAuth()
        .put(`${envBackHost}/efosinfo/${type}`, requestData)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }
  const updateEfosImageHandler = async () => {
    if (
      updateEfosImageInfo?.xstart === data?.xfrom &&
      updateEfosImageInfo?.ystart === data?.yfrom &&
      updateEfosImageInfo?.xend === data?.xto &&
      updateEfosImageInfo?.yend === data?.yto
    ) {
      await normalAlert('이미지 영역에 변화가 없습니다.')
      return
    }

    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/efosimage`, updateEfosImageInfo)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            await getList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const canvasInstance = new fabric.Canvas('imageEdit', {
        width: 320,
        height: 144,
        padding: 5,
        top: 150,
      })
      setCanvas(canvasInstance)
      setUpdateServerCycle(data?.serverCycle)
      setUpdateEfosImageInfo({
        ...updateEfosImageInfo,
        imei: data?.imei,
        xstart: data?.xfrom,
        ystart: data?.yfrom,
        xend: data?.xto,
        yend: data?.yto,
        rotate: data?.rotate,
      })

      return () => {
        canvasInstance.dispose()
      }
    }, 100)
  }, [data])

  useEffect(() => {
    if (!canvas) return
    if (data) {
      const imageUrl = envImagePath + (data?.filePath || '')
      // const slice = data?.filePath?.split('/')
      fabric.Image.fromURL(imageUrl, (img: any) => {
        const imgWidth = img ? img.width : 320
        const imgHeight = img ? img.height : 144
        if (imgWidth > canvas.width || imgHeight > canvas.height) {
          canvas.setDimensions({width: imgWidth, height: imgHeight})
        }

        img.set({
          left: canvas.width / 2,
          top: canvas.height / 2,
          width: imgWidth,
          height: imgHeight,
          originX: 'center',
          originY: 'center',
          selectable: false,
          angle: data?.rotate,
        })
        canvas.add(img)

        const horizontalRect = new fabric.Rect({
          left: data?.xfrom !== 0 ? img.left + (data?.xfrom - imgWidth / 2) : 0,
          top: data?.yfrom !== 0 ? img.top + (data?.yfrom - imgHeight / 2) : 0,
          width: data?.xto !== 0 ? data?.xto - data?.xfrom : imgWidth - 3,
          height: data?.yto !== 0 ? data?.yto - data?.yfrom : imgHeight - 3,
          scaleX: 1,
          scaleY: 1,
          fill: 'transparent',
          stroke: 'red',
          strokeWidth: 2,
          selectable: true,
          rotatable: false,
          cornerColor: 'white',
          cornerSize: 15,
        })
        horizontalRect.setControlsVisibility({
          mt: true, // 중앙 위
          mb: true, // 중앙 아래
          ml: true, // 중앙 왼쪽
          mr: true, // 중앙 오른쪽
          bl: true, // 왼쪽 아래
          br: true, // 오른쪽 아래
          tl: true, // 왼쪽 위
          tr: true, // 오른쪽 위
          mtr: false, // 회전 포인트
        })

        canvas.add(horizontalRect)
        canvas.renderAll()
      })
    }
  }, [canvas, data])

  console.log('update')
  canvas?.on('object:modified', (e: any) => {
    const activeObject = e.target
    const rect = activeObject
    const boundingBox = rect.getBoundingRect()
    const imageObject = canvas.getObjects().find((obj: any) => obj.type === 'image')
    const xstart = boundingBox.left
    const ystart = boundingBox.top
    const xend = boundingBox.left + boundingBox.width
    const yend = boundingBox.top + boundingBox.height
    const rotate = imageObject?.angle

    setUpdateEfosImageInfo({
      imei: data?.imei,
      xstart,
      ystart,
      xend,
      yend,
      rotate,
    })
  })
  useEffect(() => {
    if (data && data.latitude && data.longitude) {
      setTimeout(() => {
        let container = document?.getElementById('dashboard-detail-map')
        if (container) {
          const map = new window.kakao.maps.Map(container, {
            center: new window.kakao.maps.LatLng(data.latitude, data.longitude),
            mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
            level: 1,
            draggable: false,
            scrollwheel: false,
            zoomable: false,
          })
          var markerPosition = new window.kakao.maps.LatLng(data.latitude, data.longitude)

          var marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
          })

          marker.setMap(map)
        }
      }, 300)
    }
  }, [data])

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
        setImageLoaded(false)
        cropperRef.current?.cropper.destroy()
        setUpdateEfosImageInfo(null)
      }}
      aria-labelledby={`${window.location.pathname}-label`}
      aria-describedby={`${window.location.pathname}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'printTarget'}
      className={'printTarget'}
    >
      <Box sx={style}>
        <>
          <div>
            <div>
              <div className=''>
                <div className={'w-100 d-flex justify-content-between'}>
                  <Chip
                    label={
                      <span>
                        <strong className={'text-primary'}>{data?.customerName}</strong> 단말기 및
                        검침기 상세정보
                      </span>
                    }
                    variant={'outlined'}
                    sx={{fontSize: '20px', fontWeight: 'bold'}}
                  />
                  <CancelPresentationIcon
                    sx={{fontSize: '40px', cursor: 'pointer'}}
                    color={'error'}
                    onClick={() => {
                      onClose()
                      setImageLoaded(false)
                      cropperRef.current?.cropper.destroy()
                    }}
                  />
                </div>

                <div className=''>
                  {data && (
                    <>
                      <div className={'d-flex gap-6 col'}>
                        <div className={'col'}>
                          <div className={'row mx-0 gap-2'}>
                            <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                              <Paper
                                className={'p-4 border-bottom border-1 border-gray-400 '}
                                square
                              >
                                <h2>단말기 정보</h2>
                              </Paper>
                              <Paper className={'p-4 '} square>
                                <div className={'row gap-2'}>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 IMEI
                                    </h2>

                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.imei}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 S/N
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.serialNumber}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 업데이트 대기
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={
                                        data.deviceApplyStatus === true
                                          ? '업데이트 대기 중'
                                          : '업데이트 없음'
                                      }
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 타입
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.deviceTypeName}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 펌웨어
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.deviceVersion}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 통신상태
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.deviceStatus === true ? '정상' : '점검'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                      error={data?.deviceStatus === false}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 단말기 통신실패
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data.deviceCommFailCount + '회'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                      error={data?.deviceStatus === false}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 배터리
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.batteryLevel + '%'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 최초 설치일자
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={dayjs(data?.installAt).format('YYYY년 M월 DD일')}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 서버주기
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      style={{width: 319}}
                                      className={'mx-2 '}
                                      defaultValue={Number(data?.serverCycle)}
                                      type={'number'}
                                      onChange={(e: any) => {
                                        setUpdateServerCycle(e.target.value)
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <IconButton aria-label='resetBtn' edge='end'>
                                              <Button
                                                variant={'outlined'}
                                                size={'large'}
                                                sx={{width: 50, height: 50}}
                                                onClick={() => updateServerCycleHandler()}
                                                disabled={data?.deviceType === 'digital'}
                                              >
                                                수정
                                              </Button>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <div className={'d-flex gap-3'}>
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 위도
                                      </h2>
                                      <Button
                                        variant={'outlined'}
                                        size={'large'}
                                        onClick={() => onClickHandler()}
                                      >
                                        위치수정
                                      </Button>
                                    </div>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.latitude}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 경도
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.longitude}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                </div>
                              </Paper>
                            </div>
                          </div>
                        </div>
                        <div className={'col'}>
                          <div className={'row gap-3'}>
                            <div
                              id='dashboard-detail-map'
                              style={{width: '100%', height: 600, cursor: 'pointer'}}
                              onClick={() => {
                                window.open(
                                  `https://m.map.kakao.com/scheme/search?q=${data.addressRoad}&center=me`,
                                  '_blank'
                                )
                              }}
                              className={'rounded-2 border-gray-400 border border-1'}
                            />
                            <div className={'text-end'}>
                              <span className={'text-danger fw-bold text-end'}>
                                지도를 클릭하면 카카오맵으로 이동합니다.
                              </span>
                            </div>
                            <div className={'h-100'}>
                              <div className={'row'}>
                                <div className={'mt-2'}>
                                  <h2>검침 인식영역</h2>
                                  <div
                                    className={
                                      'w-100 border border-gray-400 border-2 rounded-2 pt-5'
                                    }
                                  >
                                    <div
                                      className={'row px-0 mx-0 justify-content-center imageCenter'}
                                    >
                                      <div>
                                        <canvas
                                          id='imageEdit'
                                          ref={canvasRef}
                                          style={{zIndex: 1000}}
                                          className={'px-0 mx-0'}
                                        />
                                      </div>
                                      <div>
                                        <Slider
                                          style={{width: 320, marginTop: 30}}
                                          defaultValue={data?.rotate}
                                          onChange={(e: any) => {
                                            // 이미지 객체를 참조합니다.
                                            const img = canvas.item(0) // 이미지가 canvas의 첫 번째 아이템이라고 가정합니다.

                                            // 이미지 객체의 angle 속성을 변경하고, 회전의 기준점을 이미지의 중앙으로 설정합니다.
                                            if (img && img.type === 'image') {
                                              img
                                                .set({
                                                  angle: e.target.value,
                                                  originX: 'center',
                                                  originY: 'center',
                                                })
                                                .setCoords()
                                              canvas.renderAll()

                                              const rect = canvas
                                                .getObjects()
                                                .find((obj: any) => obj.type === 'rect')
                                              const boundingBox = rect.getBoundingRect()
                                              const xstart = boundingBox.left
                                              const ystart = boundingBox.top
                                              const xend = boundingBox.left + boundingBox.width
                                              const yend = boundingBox.top + boundingBox.height
                                              // const xstart = rect.left
                                              // const ystart = rect.top
                                              // const xend = rect.left + rect.width
                                              // const yend = rect.top + rect.height
                                              const rotate = data?.rotate

                                              setUpdateEfosImageInfo({
                                                imei: data?.imei,
                                                xstart,
                                                ystart,
                                                xend,
                                                yend,
                                                rotate,
                                              })
                                            }
                                          }}
                                          min={-180}
                                          max={180}
                                          valueLabelDisplay='auto'
                                        />
                                      </div>
                                    </div>
                                    <div className={'my-3 text-center '}>
                                      <Button
                                        variant={'outlined'}
                                        size={'large'}
                                        className={'fs-3'}
                                        onClick={() => updateEfosImageHandler()}
                                        // disabled={!imageLoaded}
                                      >
                                        수정
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={'col '}>
                          <div className={'row mx-0 gap-2 align-self-stretch'}>
                            <div
                              className={
                                ' border border-1 border-gray-400 shadow-sm px-0 align-self-stretch'
                              }
                            >
                              <Paper
                                className={
                                  'p-4 border-bottom border-1 border-gray-400 align-self-stretch '
                                }
                                square
                              >
                                <h2>검침기 정보</h2>
                              </Paper>
                              <Paper className={'p-4 '} square>
                                <div className={'row gap-2'}>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 검침기 S/N
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.meterReadSerialNumber}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 계량기 통신상태
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.efosStatus === true ? '정상' : '점검'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 미터링 통신실패
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={
                                        data?.meterReadCommFailCount === undefined
                                          ? null
                                          : data?.meterReadCommFailCount + '회'
                                      }
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 검침기 펌웨어
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={'YIT-EFOS'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 검침기 설치일시
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.meterReadInstallDate}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 내구연한 ( 단위 : 년 )
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data.remainDurability}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 이미지 명암
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      style={{width: 319}}
                                      className={'mx-2 '}
                                      defaultValue={data?.contrast}
                                      onChange={async (e) => {
                                        setUpdateEfosInfo({
                                          ...updateEfosInfo,
                                          contrast: e.target.value,
                                        })
                                      }}
                                      disabled={data?.deviceType === 'digital'}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <IconButton aria-label='resetBtn' edge='end'>
                                              <Button
                                                variant={'outlined'}
                                                size={'large'}
                                                sx={{width: 50, height: 50}}
                                                onClick={() => updateEfosInfoHandler('contrast')}
                                                disabled={data?.deviceType === 'digital'}
                                              >
                                                수정
                                              </Button>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon />
                                      이미지 밝기
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      style={{width: 319}}
                                      className={'mx-2 '}
                                      defaultValue={data?.brightness}
                                      disabled={data?.deviceType === 'digital'}
                                      onChange={async (e) => {
                                        setUpdateEfosInfo({
                                          ...updateEfosInfo,
                                          brightness: e.target.value,
                                        })
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <IconButton aria-label='resetBtn' edge='end'>
                                              <Button
                                                variant={'outlined'}
                                                size={'large'}
                                                sx={{width: 50, height: 50}}
                                                disabled={data?.deviceType === 'digital'}
                                                onClick={() => updateEfosInfoHandler('brightness')}
                                              >
                                                수정
                                              </Button>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon />
                                      이미지 회전
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      className={'mx-2 bg-gray-300'}
                                      value={data?.rotate === undefined ? null : data.rotate + '°'}
                                      inputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={
                                      'd-flex w-100 justify-content-between align-items-center'
                                    }
                                  >
                                    <h2 className={'d-flex align-items-center gap-2'}>
                                      <DoubleArrowIcon /> 지침부 자릿수
                                    </h2>
                                    <TextField
                                      variant={'outlined'}
                                      style={{width: 319}}
                                      className={'mx-2 '}
                                      defaultValue={data?.digit}
                                      onChange={async (e) => {
                                        setUpdateEfosInfo({
                                          ...updateEfosInfo,
                                          digit: e.target.value,
                                        })
                                      }}
                                      disabled={data?.deviceType === 'digital'}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            <IconButton aria-label='resetBtn' edge='end'>
                                              <Button
                                                variant={'outlined'}
                                                size={'large'}
                                                sx={{width: 50, height: 50}}
                                                disabled={data?.deviceType === 'digital'}
                                                onClick={() => updateEfosInfoHandler('digit')}
                                              >
                                                수정
                                              </Button>
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                              </Paper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </Box>
    </Modal>
  )
}
export default DeviceDetailInfoModal
